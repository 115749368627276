export default class NetworkTest {
  static setup = () => {
    $('.ajax-test').each((_, element) => {
      let button = $(element);

      button.on('click', event => {
        $('.success').remove();
        Rails.ajax({
          url: '/network-test',
          type: event.currentTarget.dataset.type
        });
      });
    });

    $('.standard-test').each((_, element) => {
      let button = $(element);

      button.on('click', () => {
        document.querySelector(`#${button.data('type')}-form`).submit();
      });
    });
  }
}