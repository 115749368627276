/* global Stickyfill */
export default class Shared {
  static setupScrollSpyAndStickTop = () => {
    $(document).ready(function() {
      $('body').scrollspy({ target: '.scrollspy-navbar' });

      var elements = $('.sticky-top');
      Stickyfill.add(elements);

      // Deactivate any, first, before we start up another
      $(window).off('activate.bs.scrollspy');

      $(window).on('activate.bs.scrollspy', function (_, obj) {
        var ele = $('.scrollspy-navbar a[href="' + obj.relatedTarget + '"]');
        if(ele.length) {
          var offset = ele.offset().left;
          $('.scrollspy-navbar').animate({
            scrollLeft: offset - 30
          }, 300);
        }
      });

      $(window).on('scroll', function() {
        var ele = $('.scrollspy-navbar .nav-link.active');
        if(!ele.length) {
          $('.scrollspy-navbar .nav-link:first-child').addClass('active');
        }
      })
    });
  }
}