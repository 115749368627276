export default class Homepage {
  static setupCarousels = () => {
    $(document).ready(function() {
      // Ensure relevant caption on the right is activated when the slide changes
      $('#features-carousel').on('slide.bs.carousel', function(event) {
        let indicators = $('#side-indicators li .row');

        $(indicators[event.from]).removeClass('active');
        $(indicators[event.to]).addClass('active');
      });

      $('#mobile-features-carousel').on('slide.bs.carousel', function(event) {
        let indicators = $('#mobile-features-nav .nav-item');
        let target = $(indicators[event.to]);

        $(indicators[event.from]).removeClass('active');

        let left = target[0].offsetLeft;
        $('#mobile-features-nav')[0].scrollLeft = left;
        target.addClass('active');
      });
    });
  }

  static displayDemoVideo = () => {
    if($('#watch-demo-player-container').is(':visible')) {
      $('#watch-demo-player-container').slideUp();
      $('#watch-demo-video-player')[0].pause();
      $('#home-header').removeClass('header-hidden');
    } else {
      $('#watch-demo-player-container').slideDown();
      $('#watch-demo-video-player')[0].play();
      $('#home-header').addClass('header-hidden');
    }
  }
}