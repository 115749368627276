import Content from './content'
import Layouts from './layouts'
import NetworkTest from './NetworkTest'
import Sessions from './Sessions'
import Users from './Users'

export default {
  Content,
  Layouts,
  NetworkTest,
  Sessions,
  Users
}