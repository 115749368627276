import CaseStudies from './CaseStudies'
import Homepage from './Homepage'
import Shared from './Shared'
import SupportChat from './SupportChat'

export default {
  CaseStudies,
  Homepage,
  Shared,
  SupportChat,
}