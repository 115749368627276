export default class Landing {
  static setupNavigationActions = (fixedNavigation) => {
    $(document).ready(function() {
      $('.dropdown').on('show.bs.dropdown', function () {
        $(this).find('i.fal').removeClass("fa-chevron-down").addClass("fa-chevron-up");
      });
    
      $('.dropdown').on('hide.bs.dropdown', function () {
        $(this).find('i.fal').removeClass("fa-chevron-up").addClass("fa-chevron-down");
      });
    
      if(typeof fixedNavigation != "undefined") {
        $(window).scroll(function(){
          var y = $(window).scrollTop();
          if( y > 0 ){
            $("#top-navigation").addClass('shadow-sm');
          } else {
            $("#top-navigation").removeClass('shadow-sm');
          }
        });
      }
    });
  }
}